import isEmpty from 'lodash/isEmpty';

import { ID, UUID } from '../../../../../../../../types';
import { ItemIndexSidebarTeamItemUser } from '../../../../../../components/sidebars/ItemIndexSidebar/components/ItemIndexSidebarTeam';

import { TeamsFilter } from '../../../../../../../teams/components/filters/TeamsFilter';
import { SearchResultsUsersList } from '../SearchResultsUsersList';
import { RecentlySelectedUsersList } from '../RecentlySelectedUsersList';

import { CheckPermissions } from '../../../../../../../../helpers/CheckPermissions';

const clientsTab = 'clients';

interface InviteClientsTabProps {
  fullName: string;
  onChangeTeamIdsFilter: (value: { teamIds: ID[] }) => void;
  onCheckUser: (user: ItemIndexSidebarTeamItemUser, checked: boolean) => void;
  onCheckAllUsers?: (
    users: ItemIndexSidebarTeamItemUser[],
    checked: boolean
  ) => void;
  invitedUserUuids?: UUID[];
  selectedUserIds?: ID[];
  teamIds?: ID[];
}

function InviteClientsTab({
  fullName,
  onChangeTeamIdsFilter,
  onCheckUser,
  onCheckAllUsers,
  invitedUserUuids,
  selectedUserIds,
  teamIds
}: InviteClientsTabProps) {
  return (
    <>
      <CheckPermissions action={`read_clients_team_ids_filter`}>
        <div className="mt-2">
          <TeamsFilter
            name="teamIds"
            onChange={onChangeTeamIdsFilter}
            teamType="clients"
            value={teamIds}
          />
        </div>
      </CheckPermissions>

      {fullName || !isEmpty(teamIds) ? (
        <SearchResultsUsersList
          fullName={fullName}
          invitedUserUuids={invitedUserUuids}
          onCheck={onCheckUser}
          onCheckAll={onCheckAllUsers}
          selectedUserIds={selectedUserIds}
          tab={clientsTab}
          teamIds={teamIds}
        />
      ) : (
        <CheckPermissions action="read_recently_selected_clients">
          <RecentlySelectedUsersList
            i18nNoResultsMessage="messages.start_typing_to_search_for_users"
            invitedUserUuids={invitedUserUuids}
            onCheck={onCheckUser}
            onCheckAll={onCheckAllUsers}
            selectedUserIds={selectedUserIds}
            tab={clientsTab}
          />
        </CheckPermissions>
      )}
    </>
  );
}

export default InviteClientsTab;
