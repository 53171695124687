import { ID } from '../../../../../types';
import { FiltersProps } from '../../../../../helpers/filters/hooks/useFilters';

import { useTeamsFilter } from './hooks/useTeamsFilter';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { MultiSelectFilter } from '../../../../../helpers/filters/MultiSelectFilter';

interface TeamsFilterProps extends FiltersProps<ID[]> {
  teamType: string;
}

function TeamsFilter({ name, onChange, teamType, value }: TeamsFilterProps) {
  const {
    selectedTeamsFetched,
    data,
    handleFilterTeams,
    teamsLoading,
    selectedValue,
    errorMessage
  } = useTeamsFilter({ teamType, value });

  return (
    <LoadingSkeleton count={2} loaded={selectedTeamsFetched}>
      <MultiSelectFilter
        data={data}
        i18nPlaceholder="models.teams.select_with_dots"
        name={name}
        onChange={onChange}
        onInputChange={handleFilterTeams}
        optionsLoading={teamsLoading}
        selectedValue={selectedValue}
        value={value}
      />
      <AlertMessage addClassName="mt-2" message={errorMessage} />
    </LoadingSkeleton>
  );
}

export default TeamsFilter;
