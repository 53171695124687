import React, { useCallback, useMemo, useState } from 'react';
import cl from 'classnames';
import filter from 'lodash/filter';
import size from 'lodash/size';

import { I18nText } from '../../types';

import { IconsEnum } from '../../assets/icons/types';

import { useCurrentUser } from '../../auth/hooks/useAuth/useAuth';

import { TabButton } from './components/TabButton';

export type TabsHelperDataType = {
  disabled?: boolean;
  i18nText: I18nText;
  icon: IconsEnum | null;
  tooltipI18nText: string;
  value: string;
};

interface TabsHelperProps {
  data: TabsHelperDataType[];
  defaultTab: string;
  onChange: (value: string) => void;
  withoutPermissions?: boolean;
  withTabsIcons?: boolean;
  withTabsIndexes?: boolean;
}

function TabsHelper({
  data,
  defaultTab,
  onChange,
  withoutPermissions = false
}: TabsHelperProps) {
  const currentUser = useCurrentUser();

  const [tab, setTab] = useState(defaultTab);

  const handleTabChange = useCallback(
    (value) => {
      setTab(value);
      onChange && onChange(value);
    },
    [setTab, onChange]
  );

  const availableTabsCount = useMemo(
    () =>
      size(
        filter(data, (item) =>
          currentUser.hasPermissions(`read_${item.value}_tab`)
        )
      ),
    [currentUser, data]
  );

  return withoutPermissions || availableTabsCount > 1 ? (
    <nav className="border-b border-gray-100 dark:border-gray-800 -mb-px flex font-medium text-sm">
      {data.map(({ value, i18nText, icon, disabled }) =>
        withoutPermissions ||
        currentUser.hasPermissions(`read_${value}_tab`) ? (
          <TabButton
            className={cl(
              'border-b-2 py-3 px-4 rounded-t-md outline-none focus:ring-2 focus:ring-offset-0 hover:bg-gray-200 dark:hover:bg-gray-800 whitespace-nowrap',
              {
                'border-blue-500': tab === value,
                'border-transparent': tab !== value,
                disabled: disabled
              }
            )}
            key={`tab-${value}`}
            data={value}
            disabled={disabled}
            i18nText={i18nText}
            icon={icon}
            onTabChange={handleTabChange}
          />
        ) : null
      )}
    </nav>
  ) : null;
}

export default TabsHelper;
