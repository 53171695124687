import {
  FetchTeamsFilters,
  FetchTeamsSort,
  FetchTeamsPage,
  FetchTeamsLimit,
  TeamType
} from '../../teamsTypes';

import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_TEAMS_FILTERS,
  INITIAL_TEAMS_LIMIT,
  INITIAL_TEAMS_PAGE,
  INITIAL_TEAMS_SORT
} from '../../teamsConstants';

interface TeamsOptions {
  cacheKey: string;
  query: string;
  initialFilters?: FetchTeamsFilters;
  initialSort?: FetchTeamsSort;
  initialPage?: FetchTeamsPage;
  initialLimit?: FetchTeamsLimit;
  options?: {
    cacheTime?: number;
  };
}

const scope = 'teams';

function useTeams<ItemType = TeamType>({
  cacheKey,
  query,
  initialFilters = INITIAL_TEAMS_FILTERS,
  initialSort = INITIAL_TEAMS_SORT,
  initialPage = INITIAL_TEAMS_PAGE,
  initialLimit = INITIAL_TEAMS_LIMIT,
  options = {}
}: TeamsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    clearItemsFilters,
    filterItems,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
  } = useIndexQuery<ItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options,
    query,
    scope
  });

  return {
    teamsData: data,
    teams: items,
    teamsError: itemsError,
    teamsTotalCount: itemsTotalCount,
    teamsFetched: isFetched,
    teamsLoading: isLoading,
    teamsIsPlaceholderData: isPlaceholderData,
    teamsFilters: currentFilters,
    teamsSort: currentSort,
    teamsPage: currentPage,
    teamsLimit: currentLimit,
    filterTeams: filterItems,
    clearTeamsFilters: clearItemsFilters,
    sortTeams: sortItems,
    paginateTeams: paginateItems,
    limitTeams: limitItems,
    prefetchTeams: prefetchItems
  };
}

export default useTeams;
