import isEmpty from 'lodash/isEmpty';

import { ID, UUID } from '../../../../../../../../types';
import { ItemIndexSidebarTeamItemUser } from '../../../../../../components/sidebars/ItemIndexSidebar/components/ItemIndexSidebarTeam';

import { UserGroupsFilter } from '../../../../../../../users/components/filters/UserGroupsFilter';
import { SearchResultsUsersList } from '../SearchResultsUsersList';
import { RecentlySelectedUsersList } from '../RecentlySelectedUsersList';

import { CheckPermissions } from '../../../../../../../../helpers/CheckPermissions';

const workersTab = 'workers';

interface InviteWorkersTabProps {
  fullName: string;
  groupIds?: ID[];
  onChangeGroupIdsFilter: (value: { groupIds: ID[] }) => void;
  onCheckUser: (user: ItemIndexSidebarTeamItemUser, checked: boolean) => void;
  onCheckAllUsers?: (
    users: ItemIndexSidebarTeamItemUser[],
    checked: boolean
  ) => void;
  invitedUserUuids?: UUID[];
  selectedUserIds?: ID[];
}

function InviteWorkersTab({
  fullName,
  groupIds,
  onChangeGroupIdsFilter,
  onCheckUser,
  onCheckAllUsers,
  invitedUserUuids,
  selectedUserIds
}: InviteWorkersTabProps) {
  return (
    <>
      <CheckPermissions action={`read_workers_group_ids_filter`}>
        <UserGroupsFilter
          className="mt-2"
          name="groupIds"
          onChange={onChangeGroupIdsFilter}
          value={groupIds}
        />
      </CheckPermissions>

      {fullName || !isEmpty(groupIds) ? (
        <SearchResultsUsersList
          fullName={fullName}
          groupIds={groupIds}
          invitedUserUuids={invitedUserUuids}
          onCheck={onCheckUser}
          onCheckAll={onCheckAllUsers}
          selectedUserIds={selectedUserIds}
          tab={workersTab}
        />
      ) : (
        <CheckPermissions action="read_recently_selected_workers">
          <RecentlySelectedUsersList
            i18nNoResultsMessage="messages.start_typing_to_search_for_users"
            invitedUserUuids={invitedUserUuids}
            onCheck={onCheckUser}
            onCheckAll={onCheckAllUsers}
            selectedUserIds={selectedUserIds}
            tab={workersTab}
          />
        </CheckPermissions>
      )}
    </>
  );
}

export default InviteWorkersTab;
