import React, { useCallback } from 'react';

import { I18nText } from '../../../../types';

import { IconsEnum } from '../../../../assets/icons/types';
import { PureIconButtonHelperDefaultProps } from '../../../buttons/PureIconButtonHelper/PureIconButtonHelper';

import { PureIconButtonHelper } from '../../../buttons/PureIconButtonHelper';

interface TabButtonProps extends PureIconButtonHelperDefaultProps {
  data: string;
  i18nText: I18nText;
  icon: IconsEnum | null;
  onTabChange: (data: string) => void;
}

function TabButton({
  className,
  data,
  disabled,
  i18nText,
  icon,
  onTabChange
}: TabButtonProps) {
  const handleOnClick = useCallback(
    () => onTabChange(data),
    [data, onTabChange]
  );

  return (
    <PureIconButtonHelper
      blurOnMouseLeave
      className={className}
      disabled={disabled}
      i18nText={i18nText}
      icon={icon}
      onClick={handleOnClick}
    />
  );
}

export default TabButton;
